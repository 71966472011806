import React, { useState } from 'react';
import { Paper, Button, TextField, InputAdornment, Container, Box, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import toast from 'react-hot-toast';

function Login(props) {
  const { fetcher, handleSetSession } = props;
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');


  let handleClick = () => {
    if (username !== '' && password !== '') {
      fetcher(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({ username, password })
      })
        .then(res => handleSetSession(res))
        .catch((err) => {
          setPassword('');
          toast.error('El usuario o la clave es invalida');
        });
    } else {
      setPassword('');
      toast.error('El usuario o la clave es invalida');
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    handleClick();
  };

  return (
    <Container maxWidth="xs">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Paper elevation={10} style={{ padding: "30px", textAlign: "center" }}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Usuario"
                color="primary"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                label="Clave"
                type="password"
                color="primary"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Button variant="contained" fullWidth color="primary" type="submit" style={{ marginTop: "10px", marginBottom: "0" }}>Iniciar sesión</Button>
            </form>
          </Paper >
        </Grid>
      </Grid>
    </Container >
  );
}

export default Login;