import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

function WalletItem(props) {
  const { walletId, walletName, walletColor, fetcher } = props;
  const [balances, setBalances] = useState([]);
  const [anchorEl, setAnchorEl] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (walletId !== "total") {
      fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}/total`)
        .then(res => setBalances(res))
        .catch(_ => toast.error('Ha ocurrido un error'));
    } else {
      fetcher(`${process.env.REACT_APP_API_URL}/transaction/total`)
        .then(res => setBalances(res))
        .catch(_ => toast.error('Ha ocurrido un error'));
    }

  }, [walletId]);

  return <Card sx={{ textAlign: 'center', backgroundColor: walletColor }} style={{ cursor: 'pointer' }} >
    <CardHeader onClick={() => navigate(`/wallet/${walletId}`)}
      action={
        walletId !== "total" ?
          <IconButton onClick={(e) => { setAnchorEl(e.currentTarget); e.stopPropagation(); }}>
            <MoreVertIcon />
          </IconButton> : <></>
      }
      title={walletName}
    />
    <CardContent onClick={() => navigate(`/wallet/${walletId}`)}>
      {balances.map((balance, index) => <Typography variant="subtitle1" component="div" key={index}>{balance.currency}{balance.total}</Typography>)}
    </CardContent>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl()}
    >
      <MenuItem onClick={() => navigate(`/wallet/edit/${walletId}`)}>
        Editar
      </MenuItem>
    </Menu>
  </Card>
}

export default WalletItem;