import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, TextField } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';

function WalletEdit(props) {
  const { fetcher } = props;
  const { walletId } = useParams();
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (walletId) {
      fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}`)
        .then(wallet => {
          setName(wallet.name);
          setColor(wallet.color);
        })
        .catch(_ => toast.error('Ha ocurrido un error'));
    }
  }, [walletId])

  function save() {
    if(name && color){
      fetcher(`${process.env.REACT_APP_API_URL}/wallet${walletId ? `/${walletId}` : ''}`, {
        method: walletId ? "PUT" : "POST",
        body: JSON.stringify({
          name,
          color
        })
      })
        .then(_ => {
          navigate('/');
          toast.success('Billetera guardada exitosamente');
        })
        .catch(_ => toast.error('Ha ocurrido un error'));
    }else{
      toast.error('Complete todos los campos');
    }
  }

  return <Card sx={{ textAlign: 'center', marginBottom: 10 }}>
    <CardContent>
      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              value={name}
              label="Nombre"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              value={color}
              label="Color"
              fullWidth
              onChange={(e) => setColor(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => save()}>Guardar</Button>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  </Card>
}

export default WalletEdit;