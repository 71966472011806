import { useState, useEffect } from 'react';
import {
  Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Fab, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useParams, Link } from "react-router-dom";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import toast from 'react-hot-toast';

function WalletView(props) {
  const { fetcher } = props;
  const { walletId } = useParams();

  const [walletName, setWalletName] = useState();
  const [transactions, setTransactions] = useState([]);
  const [balances, setBalances] = useState([]);
  const [transactionToDelete, setTransactionToDelete] = useState();

  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const years = [2021, 2022, 2023, 2024, 2025];
  const [selectedYear, setSelectedYear] = useState(2022);

  const columns = [
    {
      field: 'datetime',
      headerName: 'Fecha',
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        const formatted = `${zeroPad(date.getDate(), 2)}/${zeroPad(date.getMonth() + 1, 2)}/${date.getFullYear()}`;
        return formatted;
      },
      width: 96
    },
    { field: 'description', headerName: 'Descripción', flex: 1, minWidth: 100 },
    { field: 'currency', headerName: 'Moneda', width: 65, align: 'right' },
    { field: 'total', headerName: 'Total', type: 'number', width: 90, headerAlign: 'left', align: 'left' },
    {
      field: 'actions', type: 'actions', width: 10, getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => tryDeleteTransaction(params.row)}
        />]
    }
  ];

  function tryDeleteTransaction(transaction) {
    setTransactionToDelete(transaction);
  }

  function deleteTransaction() {
    fetcher(`${process.env.REACT_APP_API_URL}/transaction/${transactionToDelete.id}`, { method: 'DELETE' })
      .then(_ => {
        loadData();
        setTransactionToDelete();
        toast.success('Transacción eliminada exitosamente');
      })
      .then(_ => setTransactionToDelete())
      .catch(_ => toast.error('Ha ocurrido un error'));
  }

  function loadData() {
    if (walletId !== "total") {
      fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}`)
        .then(res => setWalletName(res.name))
        .then(_ => fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}/total`))
        .then(res => setBalances(res))
        .then(_ => fetcher(`${process.env.REACT_APP_API_URL}/transaction?walletId=${walletId}`))
        .then(res => setTransactions(res))
        .catch(_ => toast.error('Ha ocurrido un error'));
    } else {
      setWalletName("Total");
      fetcher(`${process.env.REACT_APP_API_URL}/transaction/total`)
        .then(res => setBalances(res))
        .then(_ => fetcher(`${process.env.REACT_APP_API_URL}/transaction`))
        .then(res => setTransactions(res))
        .catch(_ => toast.error('Ha ocurrido un error'));
    }
  }

  useEffect(loadData, [walletId]);

  return <Box>
    <Card sx={{ textAlign: 'center', marginBottom: 10 }}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ mb: 1.5 }}>{walletName}</Typography>
        {balances.map((balance, index) => <Typography variant="h6" component="span" sx={{ paddingLeft: 1, paddingRight: 1 }} key={index}>{balance.currency}{balance.total}</Typography>)}
        {
          walletId !== "total" ?
            <div style={{ height: 400, width: '100%', marginTop: 5 }}>
              <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    rows={transactions}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                  />
                </div>
              </div>
            </div> : <></>
        }
      </CardContent>
    </Card>
    {
      balances.map((bal, index) => {
        let transactionsData = transactions.filter(e => e.currency === bal.currency).map(e => [(new Date(e.datetime)).getTime(), parseFloat(e.total)]).sort((e1, e2) => e1[0] - e2[0]);
        return <Card key={index} sx={{ textAlign: 'center', marginBottom: 10 }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ mb: 1.5 }}>Balance de {bal.currency}</Typography>
            <HighchartsReact
              constructorType={'stockChart'}
              highcharts={Highcharts}
              options={{
                plotOptions: {
                  series: {
                    cumulative: true,
                    pointInterval: 1000 * 60 * 60 * 24
                  }
                },
                series: [{
                  data: transactionsData
                }]
              }}
            />

            <Box mt={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Año</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedYear}
                  label="Año"
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
              </FormControl>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mes</TableCell>
                      <TableCell align="right">Gastos</TableCell>
                      <TableCell align="right">Ingresos</TableCell>
                      <TableCell align="right">Resultado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {months.map((month, index) => (
                      <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{month}</TableCell>
                        <TableCell align="right">
                          {bal.currency} {transactions.filter(t => {
                            let date = new Date(t.datetime);
                            return t.currency === bal.currency && date.getMonth() === index && date.getFullYear() === selectedYear && t.total <= 0;
                          }).reduce((a, b) => (parseFloat(a.total) || a) + (parseFloat(b.total) || b), 0)}
                        </TableCell>
                        <TableCell align="right">
                          {bal.currency} {transactions.filter(t => {
                            let date = new Date(t.datetime);
                            return t.currency === bal.currency && date.getMonth() === index && date.getFullYear() === selectedYear && t.total >= 0;
                          }).reduce((a, b) => (parseFloat(a.total) || a) + (parseFloat(b.total) || b), 0)}
                        </TableCell>
                        <TableCell align="right">
                          {bal.currency} {transactions.filter(t => {
                            let date = new Date(t.datetime);
                            return t.currency === bal.currency && date.getMonth() === index && date.getFullYear() === selectedYear;
                          }).reduce((a, b) => (parseFloat(a.total) || a) + (parseFloat(b.total) || b), 0)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent></Card>
      })
    }
    <Link to={`/wallet/${walletId}/add`}>
      <Fab color="primary" aria-label="add" sx={{ position: "fixed", right: 10, bottom: 10 }}>
        <AddIcon />
      </Fab>
    </Link>
    <Dialog
      open={Boolean(transactionToDelete)}
      onClose={() => setTransactionToDelete()}
    >
      <DialogTitle>
        ¿Está seguro que desea eliminar la transacción?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            transactionToDelete ? <>
              Fecha: {transactionToDelete.datetime}<br />
              Descripción: {transactionToDelete.description}<br />
              Monto: {transactionToDelete.currency} {transactionToDelete.total}
            </> : <></>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTransactionToDelete()}>Cancelar</Button>
        <Button onClick={deleteTransaction} autoFocus>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
}

export default WalletView;