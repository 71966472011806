import { useEffect, useState } from 'react';
import { Fab, Grid } from '@mui/material';
import WalletItem from './WalletItem';
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';

function WalletList(props) {
  const { fetcher } = props;
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/wallet`)
      .then(res => setWallets(res))
      .catch(_ => toast.error('Ha ocurrido un error'));
  }, []);

  return <Grid container spacing={2} justifyContent="space-around" alignItems="stretch" sx={{ marginBottom: 10 }}>
    {wallets.map(wallet => <Grid item xs={12} sm={6} md={4} key={wallet.id}>
      <WalletItem walletId={wallet.id} walletName={wallet.name} walletColor={wallet.color} fetcher={fetcher} />
    </Grid>)}
    <Grid item xs={12} sm={6} md={4}>
      <WalletItem walletId={"total"} walletName={"Total"} walletColor={"#1876d1"} fetcher={fetcher} />
    </Grid>
    <Link to={`/wallet/add`}>
      <Fab color="primary" aria-label="add" sx={{ position: "fixed", right: 10, bottom: 10 }}>
        <AddIcon />
      </Fab>
    </Link>
  </Grid>
}

export default WalletList;