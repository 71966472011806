import './App.css';
import { useEffect, useState } from 'react';
import WalletList from './WalletList';
import WalletView from './WalletView';
import WalletEdit from './WalletEdit';
import TransactionAdd from './TransactionAdd';
import Login from './Login';
import { AppBar, Backdrop, Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import { Routes, Route, Link } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

function App() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      setUser(JSON.parse(token));
    }
  }, []);

  let setSession = (user) => {
    setUser(user);
    localStorage.setItem('token', JSON.stringify(user));
  }

  let removeSession = () => {
    setUser();
    localStorage.removeItem('token');
  }

  let fetcher = (url, opt) => {
    let headers = opt ? opt.headers : {}
    if (user) {
      headers = { ...headers, 'Authorization': `Bearer ${user.token}` }
    }
    opt = { ...opt, headers: { ...headers, 'Content-Type': 'application/json' } }
    return new Promise((resolve, reject) => {
      setLoading(true);
      fetch(url, opt)
        .then(async function (response) {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401) {
              removeSession();
              reject(new Error());
            } else {
              const error = await response.json();
              throw error;
            }
          }
        })
        .then(data => resolve(data))
        .catch(e => reject(e))
        .finally(_ => setLoading(false));
    });
  }

  return (
    <Box>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>
      <Toaster position="top-right" />
      <AppBar position="static">
        <Toolbar>
          <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Billetera
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Box m={2}>
        {user ?
          <Routes>
            <Route path="/" element={<WalletList fetcher={fetcher} />} />
            <Route path="wallet/add" element={<WalletEdit fetcher={fetcher} />} />
            <Route path="wallet/edit/:walletId" element={<WalletEdit fetcher={fetcher} />} />
            <Route path="wallet/:walletId" element={<WalletView fetcher={fetcher} />} />
            <Route path="wallet/:walletId/add" element={<TransactionAdd fetcher={fetcher} />} />
          </Routes>
          :
          <Login handleSetSession={setSession} fetcher={fetcher} />
        }
      </Box>
    </Box>
  );
}

export default App;
