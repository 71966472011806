import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, TextField, Typography } from '@mui/material';
import { useParams, useNavigate } from "react-router-dom";
import AdapterMoment from '@mui/lab/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import toast from 'react-hot-toast';

function TransactionAdd(props) {
  const { fetcher } = props;
  const { walletId } = useParams();
  const navigate = useNavigate();

  const [walletName, setWalletName] = useState();
  const [balances, setBalances] = useState([]);
  const [datetime, setDatetime] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [total, setTotal] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState(0);

  useEffect(() => {
    fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}`)
      .then(res => setWalletName(res.name))
      .then(_ => fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}/total`))
      .then(res => setBalances(res))
      .catch(_ => toast.error('Ha ocurrido un error'));
  }, [walletId]);

  function addTransaction() {
    let castedTotal = total.replace(',','.');
    if (datetime && currency && !isNaN(castedTotal) && description) {
      fetcher(`${process.env.REACT_APP_API_URL}/wallet/${walletId}/total`)
        .then(res => setBalances(res))
        .then(_ => {
          let transactionTotal = 0;
          switch (type) {
            case 0: // Outgoing
              transactionTotal = -castedTotal;
              break;
            case 1: // Ingress
              transactionTotal = castedTotal;
              break;
            case 2: // Adjustment
              let foundBalance = balances.find(el => el.currency == currency);
              if (foundBalance) {
                if (foundBalance.total <= castedTotal) {
                  transactionTotal = castedTotal - Math.abs(foundBalance.total);
                } else {
                  transactionTotal = -(foundBalance.total - castedTotal);
                }
              } else {
                transactionTotal = castedTotal;
              }
              break;
          }
          return transactionTotal;
        })
        .then(transactionTotal => fetcher(`${process.env.REACT_APP_API_URL}/transaction`, {
          method: 'POST',
          body: JSON.stringify({
            datetime: datetime.toISOString().slice(0, 19).replace('T', ' '),
            walletId,
            total: transactionTotal,
            description,
            currency
          }),
        }))
        .then(_ => {
          navigate(`/wallet/${walletId}`, { replace: true });
          toast.success('Transacción guardada exitosamente');
        })
        .catch(err => toast.error('Ocurrió un error al guardar la transacción'));
    } else {
      toast.error('Complete todos los campos');
    }
  }

  return <Card sx={{ textAlign: 'center', padding: 2, marginBottom: 10 }}>
    <CardContent>
      <Typography variant="h4" component="div" sx={{ mb: 1.5 }}>{walletName}</Typography>
      {balances.map((balance, index) => <Typography variant="h6" component="span" sx={{ paddingLeft: 1, paddingRight: 1 }} key={index}>{balance.currency}{balance.total}</Typography>)}

      <Box component="form" sx={{ mt: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha"
                value={datetime}
                onChange={(newValue) => {
                  setDatetime(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              select
              value={type}
              label="Tipo"
              fullWidth
              onChange={(e) => setType(e.target.value)}
              sx={{ textAlign: "left" }}
            >
              <MenuItem value={0}>Gasto</MenuItem>
              <MenuItem value={1}>Ingreso</MenuItem>
              <MenuItem value={2}>Ajuste de saldo</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              select
              value={currency}
              label="Moneda"
              fullWidth
              onChange={(e) => setCurrency(e.target.value)}
              sx={{ textAlign: "left" }}
            >
              <MenuItem value="AR$">AR$</MenuItem>
              <MenuItem value="US$">US$</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="total-input">Total</InputLabel>
              <OutlinedInput
                id="total-input"
                value={total}
                inputProps={{ inputMode: "decimal" }}
                onChange={(e) => setTotal(e.target.value)}
                startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
                label="Total"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              value={description}
              multiline
              label="Descripción"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => addTransaction()}>Agregar</Button>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  </Card >
}

export default TransactionAdd;